<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-toolbar>
        <v-toolbar-title>Faculty New Course Admin: List</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table :items="apps" :headers="headers" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
        <template v-slot:item.submittedDate="{ item }">{{ stringFormatDate(item.submittedDate) }}</template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-tooltip v-if="item.submitted && checkApproval(item)" top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="'/student/study-abroad/admin/' + item._id" v-on="on">
                <v-icon color="success">fal fa-check</v-icon>
              </v-btn>
            </template>
            <span>Approved; Click to access application</span>
          </v-tooltip>
          <v-tooltip v-else-if="item.submitted" top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="'/student/study-abroad/admin/' + item._id" v-on="on">
                <v-icon>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>Review this application</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fal fa-pause-circle</v-icon>
              </v-btn>
            </template>
            <span>Student has started but not submitted their application.</span>
          </v-tooltip>
          <v-btn icon :to="'/student/study-abroad/admin/' + item._id + '#messages'" v-if="getUnreadMessageCount(item)">
            <v-badge :content="getUnreadMessageCount(item)" color="error" overlap>
              <v-icon>fal fa-envelope</v-icon>
            </v-badge>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => {
      return root.$store.state.user.spoof || root.$store.state.user
    })
    const apps = ref([])
    const appCount = ref(0)
    const headers = ref([
      { text: 'Course Title', value: 'Course Title' },
      { text: 'Department', value: 'Department' },
      { text: 'Requestor', value: 'Name' },
      { text: 'Effective Term', value: 'Effective Term' },
      { text: 'Submitted', value: 'createdAt' },
      { text: 'Actions', value: 'actions' }
    ])

    const service = root.$feathers.service('academics/new-course')

    function load () {
      let terms = []
      let dt = new Date()
      if (dt.getMonth() <= 4) {
        // Allow for the fall and summer of the current year
        terms.push('Spring ' + dt.getFullYear())
        terms.push('Summer ' + dt.getFullYear())
        terms.push('Fall ' + dt.getFullYear())
      } else if (dt.getMonth() < 10) {
        terms.push('Summer ' + dt.getFullYear())
        terms.push('Fall ' + dt.getFullYear())
      } else {
        terms.push('Fall ' + dt.getFullYear())
      }
      // Add two additional year's worth of term options
      dt.setFullYear(dt.getFullYear() + 1)
      terms.push('Spring ' + dt.getFullYear())
      terms.push('Summer ' + dt.getFullYear())
      terms.push('Fall ' + dt.getFullYear())
      dt.setFullYear(dt.getFullYear() + 1)
      terms.push('Spring ' + dt.getFullYear())
      terms.push('Summer ' + dt.getFullYear())
      terms.push('Fall ' + dt.getFullYear())

      const { username, roles } = user.value
      let query = { programTerm: { $in: terms }, approvals: { $elemMatch: { approved: false, $or: [ { username }, { department: { $in: roles } } ] } } }
      if ('World Languages' in root.$store.state.roles) {
        query = { $or: [ { programTerm: { $in: terms } }, { programTerm: { $exists: false } } ] }
      }
      service.find({ query }).then(({ data, total }) => {
        apps.value = data
        appCount.value = total
      })
    }

    function getUnreadMessageCount (app) {
      const username = user.value.username
      let unreadMessages = 0
      for (let i = 0; i < app.approvals.length; i++) {
        if (app.approvals[i].department in root.$store.state.roles || (app.approvals[i].department === 'Advisor' && app.approvals[i].username === username)) {
          if ('messages' in app.approvals[i] && app.approvals[i].messages.length > 0) {
            const messages = app.approvals[i].messages
            for (let j = 0; j < messages.length; j++) {
              let isRead = false
              if ('readUsername' in messages[j]) {
                for (let k = 0; k < messages[j].readUsername.length; k++) {
                  if (messages[j].readUsername[k] === username) isRead = true
                }
              }
              if (!isRead) unreadMessages++
            }
          }
        }
      }
      return unreadMessages
    }

    function checkApproval (app) {
      const username = user.value.username
      for (let i = 0; i < app.approvals.length; i++) {
        if (app.approvals[i].department in root.$store.state.roles || (app.approvals[i].department === 'Advisor' && app.approvals[i].username === username)) {
          return app.approvals[i].approved
        }
      }
      return false
    }

    onMounted(() => {
      load()

      service.on('created', load)
      service.on('patched', load)
      service.on('updated', load)
    })

    return {
      apps, appCount, headers, getUnreadMessageCount, checkApproval, stringFormatDate
    }
  }
}
</script>
